import React, { useEffect } from 'react';

// import { SearchOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
// import type { InputRef } from 'antd';
import type { ColumnsType,TableProps } from 'antd/lib/table';
// import type { FilterConfirmProps } from 'antd/lib/table/interface';

import { useNavigate } from "react-router-dom";
import MonittorService from "../../services/MonittorService";
import { CompanySummaryModel } from '../../model/Company';
import Loading from '../../component/Loading';
import SearchInput from "../../component/SearchInput";
import './CompanyList.scss'
// type DataIndex = keyof CompanySummaryModel;

function CompanyList() {

  const [data, setData] = React.useState<CompanySummaryModel[]>([]);
  const [filteredData, setFilteredData] = React.useState<CompanySummaryModel[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  // @ts-ignore
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  // const searchInput = useRef<InputRef>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const response = await MonittorService.getAllCompanies()
        setData(response);
        setFilteredData(response);
      }
      catch (e) {
        console.error(e)
      } finally {
        setLoading(false);
      }
    };
    getCompanies();
  }, []);

  const goDetail = (id: string) => {
    navigate(`/company/${id}`);
  }

  // const handleSearch = (
  //   selectedKeys: string[],
  //   confirm: (param?: FilterConfirmProps) => void,
  //   dataIndex: DataIndex,
  // ) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  // const handleReset = (clearFilters: () => void) => {
  //   clearFilters();
  //   setSearchText('');
  // };

  // const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<CompanySummaryModel> => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({ closeDropdown: false });
  //             setSearchText((selectedKeys as string[])[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered: boolean) => (
  //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       .toString()
  //       .toLowerCase()
  //       .includes((value as string).toLowerCase()),
  //   onFilterDropdownVisibleChange: visible => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100);
  //     }
  //   },
  //   render: text =>
  //     searchedColumn === dataIndex ? (
  //       text
  //     ) : (
  //       text
  //     ),
  // });

  const columns: ColumnsType<CompanySummaryModel> = [
    {
      title: 'Name',
      dataIndex: 'name',
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value as string),
      width: '30%',
      // ...getColumnSearchProps('name'),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      onFilter: (value, record) => record.email.includes(value as string),
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
      // ...getColumnSearchProps('email'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: <span>Manufacturer</span>,
          value: 'manufacturer',
        },
        {
          text: <span>Customer</span>,
          value: 'customer',
        },
      ],
      onFilter: (value, record) => record.type.includes(value as string),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      filterSearch: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button type="primary" shape="round" onClick={() => goDetail(record.id)}>
          Go to Company Detail
        </Button>
      ),
    },
  ];

  const onChange: TableProps<CompanySummaryModel>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    // console.log('searchText', searchText);
  };

  return (
    <div className='company-list-page'>
      {loading && <Loading />}
      <SearchInput
        className='search-wrapper'
        data={data}
        handleSearch={setFilteredData}
        searchKeys={['id', 'name', 'email']}
      />
      <Table columns={columns} dataSource={filteredData} onChange={onChange} />
    </div>
  )
}

export default CompanyList
