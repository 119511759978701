import React, { useEffect, useState } from 'react'
import { Button, Card, Row } from 'antd';
import MonittorService from "../../services/MonittorService";
import { useParams } from "react-router-dom";
import './CompanyDetail.scss';
import { CompanyDetailModel } from '../../model/Company';
import Loading from '../../component/Loading';
import { getAppUrl } from '../../api/helper';

function CompanyDetail() {
  let { id } = useParams();
  const [detail, setDetail] = useState(null as CompanyDetailModel | null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyDetail = async () => {
      try {
        const response = await MonittorService.getCompanyDetail({ id })
        setDetail(response)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
    }
    fetchCompanyDetail()
  }, [id]);

  if (loading) return <Loading />
  else if (detail == null) return <></>
  else {
    const getInpersonateUrl = () => {
      const url = getAppUrl();
      return url + '/inpersonate?token=' + detail.token;
    };

    return (
      <div className='company-detail-page'>
        <Row>
          <Card className='company-detail-card' title={
            <div className='company-detail-header'>
              <div className="detail-header-left">
                <span>{detail.name}</span>
              </div>
              <div className="detail-header-right">
                <a target='_blank' href={getInpersonateUrl()} rel="noreferrer">
                  <Button type='primary'>Login as</Button>
                </a>

              </div>
            </div>
          }>
            <Card title={'Company Info'} className='info-box'>
              <div><b>Id: </b>{detail.id}</div>
              <div><b>Email: </b>{detail.email}</div>
              <div><b>Type: </b>{detail.type}</div>
              <div><b>Phone: </b>{detail.phone}</div>
              <div><b>FireBase Id: </b>{detail.firebaseId}</div>
              <div><b>About: </b>{detail.about}</div>
            </Card>
            <Card title={'Primary User'} className='info-box'>
              <div><b>Id: </b>{detail.user.id}</div>
              <div><b>Email: </b>{detail.user.email}</div>
              <div><b>First Name: </b>{detail.user.firstName}</div>
              <div><b>Last Name: </b>{detail.user.lastName}</div>
              <div><b>Role: </b>{detail.user.role}</div>
            </Card>
          </Card>
        </Row>
      </div>
    )
  }
}

export default CompanyDetail
