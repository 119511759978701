import HttpClient from '../utils/HttpClient';
import { getApiUrl } from './helper';
import Interceptors from './Interceptors';

// const url = 'https://monittor-dev.ep65l0igu8bne.us-west-2.cs.amazonlightsail.com/';
const url = getApiUrl();

const options = {
    requestInterceptors: [Interceptors.spinnerForRequest],
    responseInterceptors: [Interceptors.spinnerForResponse, Interceptors.errorHandlerForResponse],
};

export const MonitorApi = new HttpClient(
    url,
    options,
);
