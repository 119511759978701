
const host = window.location.host;

export function getAppUrl() {
  switch(host) {
    case 'admin-dev.monittor.com': return 'https://dev.monittor.com/';
    case 'admin-stage.monittor.com': return 'https://demo.monittor.com/';
    case 'admin.monittor.com': return 'https://app.monittor.com/';
    default: return 'https://dev.monittor.com/';
  }
}
export function getApiUrl() {
  switch(host) {
    case 'admin-dev.monittor.com': return 'https://api-dev.monittor.com/';
    case 'admin-stage.monittor.com': return 'https://api-stage.monittor.com/';
    case 'admin.monittor.com': return 'https://api.monittor.com/';
    default: return 'https://api-dev.monittor.com/';
  }
}