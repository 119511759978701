import { Col, Row, Select, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import Loading from '../../component/Loading';
import { AuditByType, AuditFromType, AuditModel, AuditType } from '../../model/Audit';
import MonittorService from '../../services/MonittorService';
import './Audit.scss'

const { Option } = Select;

function AuditPage() {
  const [loading, setLoading] = useState(false);
  const [audits, setAudits] = useState([] as AuditModel[]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [filterAction, setFilterAction] = useState(null as AuditType | null)
  const [filterBy, setFilterBy] = useState(null as AuditByType | null)
  // const [filterById, setFilterById] = useState(null  as string | null)
  const [filterFrom, setFilterFrom] = useState(null as AuditFromType | null)
  // const [filterFromId, setFilterFromId] = useState(null as string | null)

  const getColor = (type: AuditType) => {
    switch (type) {
      case AuditType.Create: return '#558B2F';
      case AuditType.Update: return '#FF8F00';
      case AuditType.Delete: return '#C62828';
      default: return '#000'
    }
  }

  const columns: ColumnsType<AuditModel> = [
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (type: string) => <span style={{ color: getColor(type as AuditType) }}>{type.toUpperCase()}</span>,
    },
    {
      title: 'By',
      dataIndex: 'by',
      key: 'by',
    },
    {
      title: 'By ID',
      dataIndex: 'byId',
      key: 'byId',
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'From ID',
      dataIndex: 'fromId',
      key: 'fromId',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (value: string) => <span style={{ color: getColor(value as AuditType) }}>{dayjs(value).toISOString()}</span>,
    },
  ];


  useEffect(() => {
    const getAudits = async () => {
      setLoading(true);
      try {
        const response = await MonittorService.getAudits({
          page, size, action: filterAction || undefined, by: filterBy || undefined, from: filterFrom || undefined
        })
        setAudits(response.content);
        setPage(response.page);
        setSize(response.size);
        setTotalCount(response.totalElements);
      }
      catch (e) {
        console.error(e)
      } finally {
        setLoading(false);
      }
    };
    getAudits();
  }, [filterAction, filterBy, filterFrom, page, size])



  return (
    <Row className='audit-page'>
      <Col>
        {loading && <Loading />}
        <Col className='audit-filter-wrapper'>
          <Select value={filterAction} onChange={(e) => setFilterAction(e)} style={{ width: 120 }}>
            <Option value={null}>Action</Option>
            {Object.values(AuditType).map((p, i) => <Option value={p} key={i}>{p.toUpperCase()}</Option>)}
          </Select>

          <Select value={filterBy} onChange={(e) => setFilterBy(e)} style={{ width: 120 }}>
            <Option value={null}>By</Option>
            {Object.values(AuditByType).map((p, i) => <Option value={p} key={i}>{p.toUpperCase()}</Option>)}
          </Select>

          <Select value={filterFrom} onChange={(e) => setFilterFrom(e)} style={{ width: 120 }}>
            <Option value={null}>From</Option>
            {Object.values(AuditFromType).map((p, i) => <Option value={p} key={i}>{p.toUpperCase()}</Option>)}
          </Select>
        </Col>
        <Col>
          <Table pagination={{
            pageSize: size,
            defaultPageSize: 10,
            total: totalCount,
            showTotal: total => `Total ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '30', '40'],
            onShowSizeChange: (e, t) => setSize(t),
            onChange: (e) => setPage(e - 1),
          }} columns={columns} dataSource={audits}></Table>
        </Col>
      </Col>
    </Row>
  )
}

export default AuditPage