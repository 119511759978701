import React, { useState } from 'react'
import { Form, Input, Button, Row, Col } from 'antd';
import { TailSpin } from 'react-loader-spinner'

import { useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";

import './Login.scss';

function Login() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      await AuthService.login(values);
      navigate('/')
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row className='login-form'>
      <Col className='login-wrapper'>
        <h1>MONITTOR</h1>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[{ type: 'email', required: true, message: 'Please input your email!' }]}
          >
            <Input placeholder='email' />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder='password' />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button disabled={loading} type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
          {
            loading && <div className='loader-wrapper'>
              <TailSpin
                height="100"
                width="100"
                color='#e5ab00'
                ariaLabel='loading'
              />
            </div>
          }
      </Col>
    </Row>
  );
}

export default Login
