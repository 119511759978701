import React, { useEffect, useState } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const { Search } = Input;

// usage:
// const handleSearchedData = (data: CustomerEquipmentModel[]) => {
//     setFilteredData(data);
// }
// <SearchInput data={allData} handleSearch={handleSearchedData} searchKeys={['name','manufacturer.name']}/>

interface Props {
    data: any;
    searchKeys: string[];
    className?: string
    handleSearch: (data: any[]) => void;
    setDefaultSearch?: string;
}

function SearchInput(props: Props) {

    const [search, setSearch] = useState('');


    const suffix = (
      <CloseOutlined
        onClick={() => {
            setSearch('')
            handleOnChange('')
        }}
        style={{
            fontSize: 16,
            color: '#1890ff',
        }}
      />
    );

    useEffect(() => {
        handleOnChange(props.setDefaultSearch || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.setDefaultSearch])

    const handleOnChange = (e: string) => {
        const searchValue = e;
        const currentData: any[] = [];
        setSearch(searchValue)
        if (searchValue.length > 0) {
            props.data.forEach((item: any) => {
                props.searchKeys.forEach((key: string) => {
                    if (key?.includes('.')) {
                        const keys = key.split('.');
                        const value = item[keys[0]] ? item[keys[0]][keys[1]] : undefined;
                        if (value === undefined) {
                            return;
                        }
                        if (value.toLowerCase()?.includes(searchValue.toLowerCase())) {
                            if (currentData.some((addedItem: any) => addedItem.id === item.id)) {
                                return;
                            }
                            currentData.push(item);
                        }
                    } else {
                        const value = item[key];
                        if (value === undefined) {
                            return;
                        }
                        if (item[key] instanceof Array) {
                            const newValue = item[key].join(', ');
                            if (newValue.toLowerCase().includes(searchValue.toLowerCase())) {
                                if (currentData.some((addedItem: any) => addedItem.id === item.id)) {
                                    return;
                                }
                                currentData.push(item);
                            }
                            return;
                        }
                        if (value.toLowerCase().includes(searchValue.toLowerCase())) {
                            if (currentData.some((addedItem: any) => addedItem.id === item.id)) {
                                return
                            }
                            currentData.push(item);
                        }
                    }
                })
            })
            props.handleSearch(currentData)
        } else {
            props.handleSearch(props.data)
        }

    }

    return (
      <Search
        className={props.className}
        value={search}
        placeholder="Search companies"
        enterButton={<SearchOutlined/>}
        size="large"
        suffix={suffix}
        onChange={(e) => handleOnChange(e.target.value)}
      />
    )
}

export default SearchInput
