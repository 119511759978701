import { toast } from "react-toastify";

/* eslint-disable import/no-anonymous-default-export */
export default {
    spinnerForRequest: {
        onFulfilled (conf) {
            if (conf.showSpinner){
                // show spinner
            }
            return conf;
        },
        onRejected (error) {
            return Promise.reject(error);
        },
    },
    spinnerForResponse: {
        onFulfilled (response) {
            if (response.config.showSpinner){
                // show spinner
            }
            return response;
        },
        onRejected (error) {
            if (error.response) {
                if (error.response.config.showSpinner) {
                    // hide spinner
                }
            }
            return Promise.reject(error);
        },
    },
    errorHandlerForResponse: {
        onFulfilled (response) {
            return response;
        },
        async onRejected (error) {
            const { response } = error;
            if (response) {
                console.log('err response', response);
                toast.error(response.data.message);
                // const hideErrorMessage = Object.prototype.hasOwnProperty.call(
                //     response.config,
                //     'hideErrorMessage',
                // ) && response.config.hideErrorMessage;
                // if (
                //     !hideErrorMessage &&
                //     response.data.errors &&
                //     response.data.errors.length
                // ) {
                //     response.data.errors.map((err) => {
                //         toast.error(err.message);
                //         return err.message;
                //         // show error message
                //     });
                // } else if (!hideErrorMessage && response.data.error) {
                //     if (response.data.error.message) {
                //         // show error message
                //     } else {
                //         // show error message
                //     }
                // } else if (!hideErrorMessage) {
                //     // show error message
                // }
            }
            return Promise.reject(error);
        },
    },
};
