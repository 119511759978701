import React from 'react'
import { TailSpin } from "react-loader-spinner";
import './Loading.scss';

function Loading() {
    return (
        <div className='loading-overlay'>
            <TailSpin
                color={'#e5ab00'}
                height={100}
                width={100}
            />
        </div>
    )
}

export default Loading
