
export enum AuditType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum AuditByType {
  Admin = 'admin',
  User = 'user',
}

export enum AuditFromType {
  Admin = 'admin',
  Company = 'company',
  User = 'user',
  Equipment = 'equipment',
  Product = 'product',
  Document = 'document',
}


export interface AuditInputModel {
  page: number;
  size: number;
  action?: AuditType;
  by?: AuditByType;
  byId?: string;
  from?: AuditFromType;
  fromId?: string;
}


export interface AuditResponseModel {
  totalElements: number;
  totalPage: number;
  page: number;
  size: number;
  content: AuditModel[];
}

export interface AuditModel {
  id: string;
  action: AuditType;
  by: AuditByType;
  byId: string;
  from: AuditFromType;
  fromId: string;
  description?: string;
  date: string;
}
