import React from 'react';
import './App.css';
import { Routes, Outlet, Route, Navigate } from 'react-router-dom'
import Login from './pages/Auth/Login';
import CompanyList from './pages/Company/List';
import Header from './particles/Layout';
import CompanyDetail from "./pages/Company/Detail";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthService from './services/AuthService';
import AuditPage from './pages/Audit';
// require('dotenv').config();

function PrivateRoute(): React.ReactElement | null {
  const isAuth = AuthService.isAuthenticated();
  if (isAuth) {
    return <Outlet />
  } else {
    return <Navigate to="/login" />
  }
}

function App() {
  return (
    <div className="App">
      <Header>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="/"
              element={<CompanyList />}
            />
            <Route
              path="/company/:id"
              element={<CompanyDetail />}
            />
            <Route
              path="/audits"
              element={<AuditPage />}
            />
          </Route>
        </Routes>
      </Header>
      <ToastContainer pauseOnHover />
    </div>
  )
}

export default App
