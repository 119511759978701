/* eslint-disable import/no-anonymous-default-export */
import { MonitorApi } from '../api';
import { AuditInputModel, AuditResponseModel } from '../model/Audit';
import { CompanyDetailModel, CompanySummaryModel } from '../model/Company';

export default {
  login (params: any, options = {}) {
    const url = 'admin-auth/login';
    return MonitorApi.post(url, params,options);
  },
  getAllCompanies(options = {}): CompanySummaryModel[] {
    const url = 'admin/companies';
    return MonitorApi.get(url, options) as unknown as CompanySummaryModel[];
  },
  getCompanyDetail(params: any,options = {}): CompanyDetailModel {
    const url = `admin/company/${params.id}`;
    return MonitorApi.get(url, options) as unknown as CompanyDetailModel;
  },
  getAudits(options: AuditInputModel): AuditResponseModel {
    const url = `admin/audits`;
    return MonitorApi.get(url, {params: options}) as unknown as AuditResponseModel;
  }
}
