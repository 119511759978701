import Storage from '../utils/Storage'

const storage = new Storage(document);
const oneDayInMs = 24 * 60 * 60 * 1000;

class TokenService {
    getToken (tokenName = 'token') {
        return storage.getCookie(tokenName);
    }

    setToken (token, tokenName = 'token', expireDuration = 90 * oneDayInMs) {
        const exp = (new Date((new Date().getTime() + expireDuration))).toUTCString();
        document.cookie = `${tokenName}=${token || ''}; expires=${exp}; path=/`;
    }

    clearToken (tokenName = 'token') {
        storage.setCookie(tokenName, '', 0);
    }
}

export default new TokenService();
