
import { Layout, Menu } from 'antd';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BookOutlined, NumberOutlined
} from '@ant-design/icons';
import './Layout.scss';
import dayjs from 'dayjs';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import AuthService from '../../services/AuthService';

interface Props { }

const hidePath = ['/login'];

function Header(props: React.PropsWithChildren<Props>) {
  const location = useLocation();
  const navigate = useNavigate();

  const isVisible = () => {
    return !hidePath.includes(location.pathname);
  }
  
  const logout = () => {
    AuthService.logout();
    navigate('/login');
  }

  const menu: ItemType[] = [
    {
      key: 'companies',
      icon: <NumberOutlined />,
      label: 'Companies',
      onClick: () => navigate('/')
    },
    {
      key: 'audits',
      icon: <BookOutlined />,
      label: 'Audits',
      onClick: () => navigate('/audits')
    }
  ]

  if (isVisible()) {
    const { Header, Sider, Content, Footer } = Layout;
    return (
      <Layout>
        <Sider
          breakpoint="md"
          collapsedWidth="0"
        >
          <div className="logo">
            <span className='logo-text'>Monittor Admin</span>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['4']}
            items={menu}
          />
        </Sider>
        <Layout>
          <Header className="site-layout-sub-header-background header">
            <div onClick={logout} className='logout-button'><FontAwesomeIcon size='2x' icon={faSignOutAlt as IconProp} /></div>
          </Header>
          <Content style={{ margin: '24px 16px 0' }}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              {props.children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Monittor ©{dayjs().get('year')}</Footer>
        </Layout>
      </Layout>
    )
  } else {
    return <>{props.children}</>;
  }
}

export default Header