import service from './MonittorService';
import TokenService from './TokenService';

class AuthService {
    async login (payload, options) {
        const params = {
            email: payload.email,
            password: payload.password
        };
        const loginResponse = await service.login(params, options);
        this.loginWithToken(loginResponse);

        return loginResponse;
    }

    loginWithToken (payload) {
        TokenService.setToken(payload.token, 'token');
    }

    logout () {
        ['token']
            .forEach((tokenName) => TokenService.clearToken(tokenName));

        Object.keys(window.localStorage)
            .filter(key => typeof window.localStorage[key] === 'string')
            .forEach(key => window.localStorage.removeItem(key));
    }

    isAuthenticated () {
        return TokenService.getToken();
    }
}

export default new AuthService();
